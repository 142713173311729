import React from "react";
import { routes, cashierRoutes } from "../routes";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssessmentIcon from "@mui/icons-material/Assessment";

const NavbarButton = () => {
  const [open, setOpen] = React.useState(false);
  const [reportsOpen, setReportsOpen] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("zamjy"));

  // Check if user exists and has a role property
  const isCashier = user && user.role === "cashier";

  const handleClick = () => {
    setOpen(!open);
  };
  const handleReportsClick = () => {
    setReportsOpen(!reportsOpen);
  };

  return (
    <>
      {isCashier ? (
        cashierRoutes
          .filter(
            (item) =>
              typeof item.icon !== "undefined" ||
              typeof item.text !== "undefined"
          )
          .map((item, index) => (
            <ListItemButton key={index} href={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))
      ) : (
        <>
          {routes
            .filter(
              (item) =>
                typeof item.icon !== "undefined" ||
                typeof item.text !== "undefined"
            )
            .map((item, index) => (
              <ListItemButton key={index} href={item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Inventory" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} href={"/stock"}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Inventory Stock" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} href={"/products"}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Inventory Product" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}

      <ListItemButton onClick={handleReportsClick}>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {reportsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} href={"/order-report"}>
            <ListItemText primary="Order Report" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} href={"/pos-report"}>
            <ListItemText primary="POS Report" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} href={"/inventory-report"}>
            <ListItemText primary="Inventory Report" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} href={"/services-report"}>
            <ListItemText primary="Services Report" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} href={"/product-report"}>
            <ListItemText primary="Product Report" />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default NavbarButton;
