import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EventIcon from "@mui/icons-material/Event";
import React, { lazy } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import { Dashboard } from "./pages/dashboard/Dashboard";
import SellIcon from "@mui/icons-material/Sell";
import Services from "./pages/services/Services";
import ServicesAdd from "./pages/services/ServicesAdd";
import ServiceEdit from "./pages/services/ServicesEdit";
import { RoomService } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";

const Appointments = lazy(() => import("./pages/appointments/Appointments"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Products = lazy(() => import("./pages/product/Product"));
const ProductEdit = lazy(() => import("./pages/product/ProductEdit"));
const ProductAdd = lazy(() => import("./pages/product/ProductAdd"));
const Category = lazy(() => import("./pages/category/Category"));


const Stock = lazy(() => import("./pages/stock/Stock"));
const StockAdd = lazy(() => import("./pages/stock/StockAdd"));
const StockEdit = lazy(() => import("./pages/stock/StockEdit"));
const ServicesReport = lazy(() => import("./pages/reports/ServicesReport"));
const OrderReport = lazy(() => import("./pages/reports/OrderReport"));
const POSReport = lazy(() => import("./pages/reports/POSReport"));
const InventoryReport = lazy(() => import("./pages/reports/InventoryReport"));
const ProductReport = lazy(() => import("./pages/reports/ProductReport"));

const Users = lazy(() => import("./pages/users/Users"));
const UsersAdd = lazy(() => import("./pages/users/UsersAdd"));

const Promo = lazy(() => import("./pages/promo/Promo"));
const PromoAdd = lazy(() => import("./pages/promo/PromoAdd"));
const PromoEdit = lazy(() => import("./pages/promo/PromoEdit"));

const POS = lazy(() => import("./pages/pos/pos"));

export const routes = [
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    icon: <DashboardIcon />,
    text: "POS",
    path: "/pos",
    element: <POS />,
  },
  {
    icon: <EventIcon />,
    text: "Reservations",
    path: "/reservations",
    element: <Appointments />,
  },
  {
    icon: <GroupsIcon />,
    text: "Users",
    path: "/users",
    element: <Users />,
  },
  {
    icon: <SettingsIcon />,
    text: "Categories",
    path: "/categories",
    element: <Category />,
  },
  {
    path: "/users/add",
    element: <UsersAdd />,
  },
  {
    icon: <ShoppingCartIcon />,
    text: "Orders",
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/products/edit/:id",
    element: <ProductEdit />,
  },
  {
    path: "/products/add",
    element: <ProductAdd />,
  },
  {
    path: "/stock",
    element: <Stock />,
  },
  {
    path: "/stock/add",
    element: <StockAdd />,
  },
  {
    path: "/stock/edit/:id",
    element: <StockEdit />,
  },
  {
    path: "/services-report",
    element: <ServicesReport />,
  },
  {
    path: "/inventory-report",
    element: <InventoryReport />,
  },
  {
    path: "/order-report",
    element: <OrderReport />,
  },
  {
    path: "/product-report",
    element: <ProductReport />,
  },

  // {
  //   icon: <SellIcon />,
  //   text: "Promo",
  //   path: "/promo",
  //   element: <Promo />,
  // },
  // {
  //   path: "/promo/add",
  //   element: <PromoAdd />,
  // },
  // {
  //   path: "/promo/edit/:id",
  //   element: <PromoEdit />,
  // },
  {
    path: "/menu",
    text: "Menu",
    element: <Services />,
    icon: <RoomService />,
  },
  {
    path: "/menu/edit/:id",
    element: <ServiceEdit />,
  },
  {
    path: "/menu/add",
    element: <ServicesAdd />,
  },
  {
    path: "/pos-report",
    element: <POSReport />,
  },
];


export const cashierRoutes = [
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    icon: <DashboardIcon />,
    text: "POS",
    path: "/pos",
    element: <POS />,
  },
  {
    icon: <ShoppingCartIcon />,
    text: "Orders",
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/services-report",
    element: <ServicesReport />,
  },
  {
    path: "/inventory-report",
    element: <InventoryReport />,
  },
  {
    path: "/product-report",
    element: <ProductReport />,
  },
  {
    path: "/order-report",
    element: <OrderReport />,
  },
  {
    path: "/pos-report",
    element: <POSReport />,
  },
 
 
];